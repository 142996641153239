import $ from 'jquery';
import 'bootstrap';
import 'parsleyjs';
import  Cookies from 'js-cookie';
import "magnific-popup";
import 'jquery-match-height'
import AOS from 'aos';
import Swiper from 'swiper';
import 'bootstrap-select';
window.$ = window.jQuery = $;
import './lib/jquery.nav';


let Page = Page || {};
(function($) {

    /* ---------------------------------------------
        Global Variables
    --------------------------------------------- */
    var $window = $(window),
        $html = $('html'),
        $body = $('body');
    
    /* ---------------------------------------------
        APP document Ready
    --------------------------------------------- */
    Page.documentOnReady = {
        init: function() {
            Page.initialize.init();
        }
    };
    
    /* ---------------------------------------------
        Page document Load
    --------------------------------------------- */
    Page.documentOnLoad = {
        init: function() {
            Page.initialize.preloader();
        }
    };
    
    /* ---------------------------------------------
        APP document Resize
    --------------------------------------------- */
    Page.documentOnResize = {
        init: function() {
            var t = setTimeout(function() {
                //
            }, 250);
        }
    };
    
    /* ---------------------------------------------
        Scripts initialization
    --------------------------------------------- */
    $(document).ready( Page.documentOnReady.init);
    $window.on('load', Page.documentOnLoad.init);
    $window.on('resize', Page.documentOnResize.init);
    
    /* ------------------------------------------------------------------------------------------------------------------
        #############################################################################################################
    ------------------------------------------------------------------------------------------------------------------ */

    Page.initialize = {
        init: function() {
            Page.initialize.matchHeight();
            Page.initialize.aos();
            Page.initialize.cookie();
            Page.initialize.swiper();
            Page.initialize.header();
            Page.initialize.innerLinks();
            Page.initialize.footer();
            Page.initialize.formValidation();
            Page.initialize.magnificPopup();
            Page.initialize.scrollSection();
        },
        
        /* ---------------------------------------------
            Preloader
        --------------------------------------------- */
        preloader: function() {
            $body.removeClass("loading");
            $body.addClass("loaded");
        },

        /* ---------------------------------------------
            matchHeight
        --------------------------------------------- */
        matchHeight: function() {
            if ($('.cards-list').length > 0) {
                $('.cards-list .card-body').matchHeight({
                    byRow: false,
                    property: 'height',
                    target: null,
                    remove: false
                });
            }

            if ( $('.partners-list').length > 0 ){
                $('.partners-list .partner-image').matchHeight({
                    byRow: false,
                    property: 'height',
                    target: null,
                    remove: false
                });
            }

            if ( $('#swiper-promo').length > 0 ){
                $('#swiper-promo .slide-inner-body').matchHeight({
                    byRow: false,
                    property: 'height',
                    target: null,
                    remove: false
                });
            }
        },

        /* ---------------------------------------------
            Footer
        --------------------------------------------- */
        footer: function () {
            $(document).on('mouseenter', '.footer [data-sub]', function () {
                var category = $(this).attr('data-sub');
                $('.fb-col-offer-category ul li').removeClass('active');
                $(this).parent('li').addClass('active');
                $('.fb-col-subcategory ul[data-category]').removeClass('active');
                $('.fb-col-subcategory ul[data-category="'  + category  +'"]').addClass('active');
            });
        },

        /* ---------------------------------------------
            scroll section
        --------------------------------------------- */
        scrollSection: function(){
            if ( $('#section-scroll-menu').length > 0 ) {
                $('#section-scroll-menu').onePageNav({
                    currentClass: 'active',
                    changeHash: false,
                    scrollSpeed: 750,
                    scrollThreshold: 0.5,
                    filter: '',
                    easing: 'swing',
                    begin: function() {
                        //I get fired when the animation is starting
                       
                    },
                    end: function() {
                        //I get fired when the animation is ending
                    },
                });
            }
        },

        /* ---------------------------------------------
            Cookie
        --------------------------------------------- */
        cookie: function () {
            let $cookieDiv = $("#cookie");
            let cookie_delay = 3000;
            if ( !Cookies.get('cookie-notyfication') ) {
                setTimeout(function () {
                    $cookieDiv.addClass("show");
                }, cookie_delay);
                $('#cookie-close').on('click', function () {
                    Cookies.set('cookie-notyfication', 'accept', { expires: 365 });
                    $cookieDiv.removeClass("show");
                });
            } 
        },
        
        /* ---------------------------------------------
            Aos
        --------------------------------------------- */
        aos: function() {
            document.addEventListener('aos:in', function( aosElem ) {
                // aos in
            });
              
            document.addEventListener('aos:out', function( aosElem ) {
                // aos out
            });
            AOS.init({
                // Global settings
                disable: $(window).width() < 1199, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
                startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
                initClassName: 'aos-init', // class applied after initialization
                animatedClassName: 'aos-animate', // class applied on animation
                useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
              
                // Settings that can be overriden on per-element basis, by `data-aos-*` attributes:
                offset: 250, // offset (in px) from the original trigger point
                delay: 500, // values from 0 to 3000, with step 50ms
                duration: 400, // values from 0 to 3000, with step 50ms
                easing: 'ease', // default easing for AOS animations
                once: true, // whether animation should happen only once - while scrolling down
                mirror: false, // whether elements should animate out while scrolling past them
                anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
            });
        },

        /* ---------------------------------------------
            Form validation
        --------------------------------------------- */
        formValidation: function () {
            //Global validation
            window.Parsley.on('field:error', function() {
                this.$element.addClass('form-control-danger');
            });
                
            window.Parsley.on('field:success', function() {
                this.$element.removeClass('form-control-danger');
                this.$element.addClass('form-control-success');
            });

            // Form gruop heleper classes
            $('form[novalidate]').parsley({
                successClass: "has-success",
                errorClass: "has-danger",
                classHandler: function(el) {
                    return el.$element.closest(".form-group");
                },
                errorsWrapper: '<ul class="parslay-danger"></ul>',
                errorElem: '<li></li>'
            });

            $('form[novalidate]').on('submit', function( evt){
                let form = $(this);
                form.parsley().validate();
                
                if (form.parsley().isValid()){
                    if ( form.attr('data-prevent') === 'true' ){
                        evt.preventDefault();
                    }

                    if ( form.attr('data-modal-show')){
                        var modal = form.attr('data-modal-show');
                        form[0].reset();
                        $(modal + "").modal('show')
                    }
                }
            });

            //Wizzard
            if( $('#wizzard-form') ){
                var $sections = $('.form-wizzard-section');
                var $steps = $('.modal-step');
                
                function navigateTo(index) {
                    // Mark the current section with the class 'current'
                    $sections
                    .removeClass('current')
                    .eq(index)
                    .addClass('current');

                    //steps menu
                    $steps.html( index + 1 +  '/' + $sections.length )
                }

                function curIndex() {
                    // Return the current index by looking at which section has the class 'current'
                    return $sections.index($sections.filter('.current'));
                }

                // Previous button is easy, just go back
                $('#form-wizzard .trigger-prev-step').click(function() {
                    navigateTo(curIndex() - 1);
                });
                
                // Next button goes forward if current block validates
                $('#form-wizzard .trigger-next-step').click(function() {
                    $('#form-wizzard').parsley().whenValidate({
                        group: 'block-' + curIndex()
                    }).done(function() {

                        $('.form-wizzard-section').removeClass('has-error');
                        navigateTo(curIndex() + 1);
                    }).catch(function(){
                        $('.form-wizzard-section.current').addClass('has-error');
                    });
                });
                
                // Prepare sections by setting the `data-parsley-group` attribute to 'block-0', 'block-1', etc.
                $sections.each(function(index, section) {
                    $(section).find(':input').attr('data-parsley-group', 'block-' + index);
                });
                navigateTo(0); // Start at the beginning
            }
        },

        /* ---------------------------------------------
            magnificPopup
        --------------------------------------------- */
        magnificPopup: function() {
            let $mfpGallery = $('.mfp-gallery');
            if ($mfpGallery.length) {
                $mfpGallery.each(function() { // the containers for all your galleries
                    $(this).magnificPopup({
                        delegate: 'a:not(.disable-mfp)', // the selector for gallery item
                        type: 'image',
                        //mainClass: 'mfp-with-zoom', // this class is for CSS animation below
                        mainClass: 'mfp-zoom-in',
                        showCloseBtn: true,
                        closeBtnInside: true,
                        closeOnContentClick: true,
                        closeMarkup: '<span class="mfp-close"><span class="icon-cross-out"></span></span>',
                        gallery: {
                            enabled: true,
                            arrowMarkup: '<span title="%title%" class="mfp-arrow mfp-arrow-%dir%"></span>', // markup of an arrow button
                        },
                        image: {
                            // titleSrc: function(item) {
                            //     return item.el.find('.overlay-content').html();
                            // }
                        },
                        removalDelay: 300, //delay removal by X to allow out-animation
                        callbacks: {
                            open: function() {
                                //overwrite default prev + next function. Add timeout for css3 crossfade animation
                                $.magnificPopup.instance.next = function() {
                                    var self = this;
                                    self.wrap.removeClass('mfp-image-loaded');
                                    setTimeout(function() { $.magnificPopup.proto.next.call(self); }, 120);
                                }
                                $.magnificPopup.instance.prev = function() {
                                    var self = this;
                                    self.wrap.removeClass('mfp-image-loaded');
                                    setTimeout(function() { $.magnificPopup.proto.prev.call(self); }, 120);
                                }
                            },
                            imageLoadComplete: function() {
                                var self = this;
                                setTimeout(function() { self.wrap.addClass('mfp-image-loaded'); }, 16);
                            }
                        }
                    });
                });
            };

            $('.popup-video').magnificPopup({
                disableOn: 700,
                type: 'iframe',
                mainClass: 'mfp-fade',
                removalDelay: 160,
                preloader: false,
                fixedContentPos: false
            });

            $('[data-lightbox="inline"]').magnificPopup({
                type:'inline',
            });
        },

        /* ---------------------------------------------
            Inner links
        --------------------------------------------- */
        innerLinks: function(){
            if($('.inner-link').length){
                $('.inner-link').on('click', function (e) {
                    e.preventDefault();
                    var href = $(this).attr('href');
                    var speed = parseInt( $(this).attr('data-speed'), 10) || 900;
                    var offset = $(this).attr('data-offset') || $('.header').outerHeight() - 40;
                    $('html, body').animate({
                        scrollTop: $(href).offset().top - offset
                    }, speed);
                    return false;
                });
            }
        },

        /* ---------------------------------------------
            Header, main menu
        --------------------------------------------- */
        header: function () {
            let $offcanvasMenu= $('#offcanvas');
            let $toggleMenu = $("#toggle-menu");
            
            //menu
            $toggleMenu.click(function(){
                $(this).toggleClass("is-active");
                $offcanvasMenu.toggleClass('active');
                $('body').toggleClass("menu-is-active");
            });


            $( window ).on( 'hashchange', function( e ) {
                if ( $('body').is(".menu-is-active") ){
                    $toggleMenu.removeClass('is-active');
                    $offcanvasMenu.removeClass('active');
                    $('body').removeClass("menu-is-active");
                }
            });
        },
        
        /* ---------------------------------------------
            swiper
        --------------------------------------------- */
        swiper: function(){
            // swiper home
            if ( $('#swiper-home').length > 0 ) {
                let $homeSwiper = $('#swiper-home');
                let homeSwiperLength = $homeSwiper.find('.swiper-slide').length;
               
                var swiperHome = new Swiper('#swiper-home', {
                    loop: homeSwiperLength > 1,
                    autoplay: {
                        delay: 7000,
                        disableOnInteraction: false
                    },
                    effect: 'fade',
                    fadeEffect: {
                        crossFade: true
                    },
                    
                    pagination: {
                        el: '#swiper-home-pagination',
                        clickable: true,
                        type: 'bullets',
                    },
                    speed: 3000,
                    simulateTouch: false,
                    touchRatio: 0,
                    allowTouchMove: false,
                    on: {
                        slideChange: function(){}
                    },
                });
            }

            // swiper offcanvas
            if ( $('#swiper-offcanvas').length > 0 ) {
                var swiperOffcanvas = new Swiper('#swiper-offcanvas', {
                    loop: false,
                    effect: 'fade',
                    fadeEffect: {
                        crossFade: true
                    },
                    simulateTouch: false,
                    touchRatio: 0,
                    allowTouchMove: false,
                });

                $('#offcanvas-menu > li').on('mouseover', function(){
                    var index = $(this).index();
                    if ( index + 1 <= swiperOffcanvas.slides.length ) {
                        swiperOffcanvas.slideTo(index, 1400, false);
                    }
                })
            }

            // swiper promo
            if ( $('#swiper-promo').length > 0 ) {
                var swiperNews = new Swiper('#swiper-promo', {
                    slidesPerView: 2,
                    spaceBetween: 20,
                    pagination: {
                        el: '#swiper-promo-pagination',
                        clickable: true,
                    },
                    breakpoints: {
                        // when window width is <= 320px
                        992: {
                            slidesPerView: 1,
                        },
                    }
                });
            }

            // swiper offer
            if ( $('#swiper-offer').length > 0 ) {
                var swiperOffer = new Swiper('#swiper-offer', {
                    loop: false,
                    effect: 'fade',
                    fadeEffect: {
                        crossFade: true
                    },
                    simulateTouch: false,
                    touchRatio: 0,
                    allowTouchMove: false,
                });

                $('#offer-menu > li').on('mouseover', function(){
                    var index = $(this).index();
                    if ( index + 1 <= swiperOffer.slides.length ) {
                        swiperOffer.slideTo(index, 1400, false);
                    }
                })
            }
        },
    };
})(jQuery);



